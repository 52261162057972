"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_1 = require("react-native");
const { height } = react_native_1.Dimensions.get('window');
const AnimatedView = react_native_1.Animated.createAnimatedComponent(react_native_1.View);
const App = (props) => {
    const {} = props;
    const opacity = new react_native_1.Animated.Value(1);
    const animate = () => {
        react_native_1.Animated.sequence([
            react_native_1.Animated.timing(opacity, {
                toValue: 0.4,
                duration: 250,
            }),
            react_native_1.Animated.timing(opacity, {
                toValue: 1,
                duration: 250,
            }),
        ]).start();
    };
    return (React.createElement(react_native_1.View, { style: { height: height } },
        React.createElement(react_native_1.TouchableWithoutFeedback, { onPress: animate },
            React.createElement(AnimatedView, { style: {
                    height,
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: opacity,
                } },
                React.createElement(react_native_1.Text, null, "Hello, World!!")))));
};
exports.default = App;
